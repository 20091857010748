import React from "react"
import { Layout, SEO } from "@global"
import { graphql } from "gatsby"
import { htmlDecode } from "@utils"
import { Imgix } from "@elements"

const TestimonialsPage = ({
  data: {
    wpgraphql: { testimonials },
  },
}) => {
  return (
    <Layout>
      <SEO title="Testimonials" />
      <section>
        <div className="container px-4 pt-20">
          <h1 className="text-3xl leading-tight tracking-tight sm:text-4xl lg:text-5xl font-display text-center ">
            Testimonials
          </h1>
        </div>
      </section>
      <section className="container py-10 lg:py-20 px-4">
        <div className="flex flex-col max-w-4xl m-auto">
          {testimonials.edges.map(({ node }) => {
            return (
              <div key={node.id} id={node.slug} className="w-full">
                <article
                  key={node.ctTestimonialFields.author}
                  className="testimonial relative z-10 p-8 bg-white shadow-xl lg:px-10 mb-12"
                >
                  <h2 className="mb-4 text-xl font-medium text-gray-400">
                    {node.title}
                  </h2>
                  <p
                    className={`text-gray-400 lg:text-lg font-display font-light `}
                  >
                    {htmlDecode(node.content)}
                  </p>
                  <footer className="flex items-center mt-4">
                    {node.featuredImage && node.featuredImage.sourceUrl && (
                      <div className="flex-none w-12 h-12 mr-4 overflow-hidden rounded-full">
                        <Imgix
                          width={48}
                          height={48}
                          src={node.featuredImage.sourceUrl}
                          alt={`Review photo`}
                          className={`object-cover w-full h-full `}
                        />
                      </div>
                    )}
                    {node.ctTestimonialFields.author && (
                      <span
                        className={`inline-block font-semibold leading-tight uppercase font-display `}
                      >
                        {node.ctTestimonialFields.author}
                      </span>
                    )}
                  </footer>
                </article>
              </div>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}
export default TestimonialsPage

export const TestimonialPageQuery = graphql`
  query TestimonialPageQuery {
    wpgraphql {
      ...Testimonials
    }
  }
`
